import React from "react";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Bold = ({ style, children }) => <span style={style}>{children}</span>;

const BoldContentful = (richText, colorBold, fontWeight = "700") => {
  const style = { color: colorBold, fontWeight: fontWeight };
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold style={style}>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.LIST_ITEM]: (node, children) => {
        const UnTaggedChildren = documentToReactComponents(node, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => children,
            [BLOCKS.LIST_ITEM]: (node, children) => children,
          },
        });

        return <li>{UnTaggedChildren}</li>;
      },
    },
  };
  return documentToReactComponents(richText, options);
};

export default BoldContentful;
